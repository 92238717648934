<template>
    <div class="w-full mx-auto slide-in">
        <div class="flex justify-center items-center">
            <div class="bg-white w-4/5 border border-gray-300 rounded">
                <div class="tracking-wide text-sm text-header-color px-8 py-4 font-semibold">
                    <em class="fas fa-upload mr-2"></em>Upload enrollees in bulk
                </div>

                <div class="border-t border-gray-200"/>
                <div class="pt-8">
                    <spreadsheet-uploader
                        download-btn-text="Download Sample"
                        sample-file="Enrollees"
                        :fields-required="requiredFields"
                        :fields="fields"
                        v-model="spreadsheet_items"
                            upload-url="enrollees/insert-bulk"
                    ></spreadsheet-uploader>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import SpreadsheetUploader from "@/shared/spreadsheet-uploader/SpreadsheetUploader";
    export default {
        name: "ImportBulk",
        components: {'spreadsheet-uploader': SpreadsheetUploader},
        data () {
            return {
                spreadsheet_items: null,
                fields:['FIRST_NAME','MIDDLE_NAME',
                    'LAST_NAME','STAFF_NO','SEX','BIRTHDATE','EMAIL','PHONE','ADDRESS',
                    'PLAN','RELATIONSHIP','RELATED_TO'
                ],

            requiredFields: ['FIRST_NAME','LAST_NAME','SEX','STAFF_NO'],
        }
    },
}
</script>

<style scoped>

</style>