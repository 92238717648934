import XLSX from 'xlsx';

const jsonToExcel = {
    methods: {
        downloadJsonToExcel(data,wsName="Skipped Items",filename="Skipped Items") {
            let wb = XLSX.utils.book_new(), ws = XLSX.utils.json_to_sheet(data);
            XLSX.utils.book_append_sheet(wb, ws, wsName);

            /* write workbook */
            XLSX.writeFile(wb, `${filename}.xlsx`);
        }
    }
}

export default jsonToExcel;