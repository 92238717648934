<template>
    <div>
        <div class="">
            <div class="text-sm text-white p-8 flex space-x-8 justify-center">
                <div>
                    <button class="bg-primary py-2 px-4 rounded" @click="downloadSample($event)">
                        <i class="fa fa-download mr-2"></i> {{downloadBtnText}}
                    </button>
                </div>

                <div>
                    <button class="bg-primary py-2 px-4 rounded" @click="selectFile($event)">
                        <i class="fa fa-file-excel mr-2"></i> Select File
                    </button>
                </div>

            </div>
            <input type="file" id="bulk_enrollee_upload_file_input"
                   class="hidden"
                   @change="handleFile($event)"
                   accept=".xls,.csv,.xlsx"
                   style="overflow:hidden;"
                   :value="modelValue" @input="$emit('update:modelValue', $event.target.value)"
            >
            <div class="text-center px-4 py-3 space-y-2" v-if="form.filename">
                <div>
                    <b>{{form.filename}}</b> : {{form.sheet_item_count}} rows
                </div>

                <div class="bg-red-200 px-2 py-2 text-red-900 rounded" v-if="form.error_msg">
                    <span v-html="form.error_msg"></span>
                </div>

                <button v-if="!form.is_processing && form.sheet_valid && uploadUrl" class="bg-primary text-white py-2 px-4 rounded" @click="submitItems()"><i class="fa fa-upload"></i> Submit</button>
            </div>

            <div class="d-block text-center bg-primary text-white" v-if="form.is_processing">Importing... Please wait</div>
            <div class="d-block text-center bg-green-500 text-white" v-if="form.processing_success===true">
                Import Completed. You will be notified when approved.
                <span v-if="skippedItemsCount" class="block">
                    Some enrollees were skipped. <span class="hover:bg-white-700 cursor-pointer" @click="downloadJsonToExcel(importResponse.skipped_items)"><strong>View Them</strong></span>
                </span>
            </div>
            <div class="d-block text-center bg-red-500 text-white" v-if="form.processing_success===false">Import Failed</div>
        </div>
    </div>
</template>

<script>
    import XLSX from "xlsx";
    import {Axios} from "@/shared/axios";
    import jsonToExcel from "@/shared/mixins/JsonToExcel";

    export default {
        name: "SpreadsheetUploader",

        props: {
            uploader: String,
            uploadUrl: String,
            uploadData: Object,
            uploadParam: String,
            onSuccess: String,
            onError: String,
            fields: Array,
            fieldsRequired: Array,
            downloadBtnText: String,
            sampleFile: String,
            theme: String,
            jsProcessor: String,
            jsProcessorButtonText: String,
            modelValue:{}
        },

        mixins: [jsonToExcel],

        data() {
            return {
                form: {
                    is_processing:false,
                    processing_success:null,
                    fields_ready:false,
                    error_msg:null,
                    sheet_valid:null,
                    filename:null
                },
                importResponse: null,
                skippedItemsCount: 0,
                sheet_items: [],
                missingFields: [],
                btnTheme: 'btn btn-' + (this.theme ?? 'primary'),
            }
        },

        mounted() {
            console.log('props', this.sampleFile);
        },

        methods: {
            selectFile(e) {
                e.preventDefault();
                this.clearFormParam();
                document.getElementById('bulk_enrollee_upload_file_input').click();
            },

            //download sample
            downloadSample(e) {
                e.preventDefault();
                this.clearFormParam();

                let filename = `Sample upload ${this.sampleFile}.xlsx`;
                let data = [{}]
                this.fields.forEach(function (item) {
                    data[0][item] = ''
                })
                let wb = XLSX.utils.book_new(), ws = XLSX.utils.json_to_sheet(data);
                XLSX.utils.book_append_sheet(wb, ws, 'Upload Sheet');
                XLSX.writeFile(wb, filename);
            },

            handleFile(e) {
                e.preventDefault();

                let vm = this;
                vm.clearFormParam();
                let rABS = true; // true: readAsBinaryString ; false: readAsArrayBuffer
                let files = e.target.files, f = files[0];
                if(!f) return false;

                let reader = new FileReader();
                reader.onload = function(e) {
                    let data = e.target.result;
                    if(!rABS) data = new Uint8Array(data);
                    let workbook = XLSX.read(data, {type: rABS ? 'binary' : 'array'});

                    let arr = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]],{raw:false})
                    vm.sheet_items = arr;

                    vm.form.filename = f.name
                    vm.form.sheet_item_count = arr.length;

                    vm.checkFields();
                };
                if(rABS) reader.readAsBinaryString(f); else reader.readAsArrayBuffer(f);
            },


            //validate spreadsheet has correct fields
            checkFields() {
                let vm = this;
                this.fieldsRequired.forEach(function (field) {
                    if(!vm.sheet_items.some(row => {
                        return row.hasOwnProperty(field) && row[field] !== ""
                    })) vm.missingFields.push(field)
                })

                if(this.missingFields.length>0){
                    this.form.error_msg = `The selected sheet has ${this.missingFields.length} missing fields <br>(${this.missingFields.join(', ')})!<br> Please download the sample sheet for reference.`
                    this.form.sheet_valid = false;
                } else {
                    this.form.sheet_valid = true;
                    this.form.error_msg = null;

                }

                console.log('form', this.form);
            },



            //push data using supplied upload url
            submitItems() {
                this.form.is_processing = true
                this.form.filename = null;
                this.$emit('processing')

                if(this.uploadUrl) {
                    let requestData = this.uploadData || {}
                    let itemsParam = this.uploadParam || 'items'
                    requestData[itemsParam] = this.sheet_items

                    Axios.post(this.uploadUrl,requestData)
                        .then((response)=>{
                            this.$emit('success', {data:response})
                            this.importResponse = response.data;
                            this.skippedItemsCount = response.data.skipped_items.length;
                            this.form.processing_success = true;
                        })
                        .catch((err)=>{
                            console.error(err)
                            this.$emit('error', {error:err});
                            this.form.processing_success = false;
                        })
                        .then(()=>{this.form.is_processing = false})
                }
            },

            //method to perform frontend processing of data
            jsProcessItems() {
                this.jsProcessor({data:sheet_items})
            },

            clearFormParam() {
                this.form = {
                    is_processing:false,
                    fields_ready:false,
                    error_msg:null,
                    sheet_valid:null,
                    filename:null
                };
                this.sheet_items = [];
                this.missingFields = [];
            }
        }
    }
</script>